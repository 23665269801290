import * as React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@material-ui/core/Grid'

export default function UnauthenticatedLandingPage() {
    const history = useHistory()
    const { authState } = useOktaAuth()
    const { isAuthenticated } = authState || {}

    // if logged in, go directly into the secure content url
    if (isAuthenticated) history.push('/search')

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item>
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image="/greybase-brand.png"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Greybase
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Welcome to Greybase 3.0
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={async () => history.push('/login')}
                            sx={{ width: '100%' }}>
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}
