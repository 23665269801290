import { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import { useOktaAuth } from '@okta/okta-react'
import { useDispatch } from 'react-redux'
import { Image } from 'mui-image'
import { useSelector } from 'react-redux'
import { setUserTag, fetchUserRole } from '../../redux/appSlice'
import tagsByEmail from '../../tagsByEmail'
import useResponsive from '../../hooks/useResponsive'
import SearchIcon from '@mui/icons-material/Search'
import ClassIcon from '@mui/icons-material/Class'
import SettingsIcon from '@mui/icons-material/Settings'

let pages = ['Tags', 'Search']

const getUserTag = (tagsByEmail, email) => {
    if (!tagsByEmail || !tagsByEmail[email]) return ''
    return tagsByEmail[email].userTag
}

const getAvatarSrc = (tagsByEmail, email) => {
    if (!tagsByEmail || !tagsByEmail[email]) return ''
    return tagsByEmail[email].avatarSrc
}

const getName = (tagsByEmail, email) => {
    if (!tagsByEmail || !tagsByEmail[email]) return email.split('@')[0]
    return tagsByEmail[email].name
}

const ResponsiveAppBar = () => {
    const dispatch = useDispatch()
    const [anchorElNav, setAnchorElNav] = useState(false)
    const [anchorElUser, setAnchorElUser] = useState(false)
    const [isDevelopment, setIsDevelopment] = useState(false)
    const theme = useTheme()
    const PRIMARY_MAIN = theme.palette.primary.main
    const userRole = useSelector((state) => state.app.userRole)
    const isMobile = useResponsive('down', 'sm')

    useEffect(() => {
        setIsDevelopment(
            window.location.href.indexOf('localhost') > 0 ||
                window.location.href.indexOf('greybase-dev') > 0 ||
                window.location.href.indexOf('next-greybase') > 0
        )
        const email = isAuthenticated ? authState.idToken.claims.email : ''
        const userTag = getUserTag(tagsByEmail, email)
        if (userTag) {
            dispatch(setUserTag(userTag))
        }
        dispatch(fetchUserRole(email))
    }, [])

    const { oktaAuth, authState } = useOktaAuth()
    if (!oktaAuth || !authState) return null
    const { isAuthenticated } = authState || {}

    const logout = async () => {
        localStorage.removeItem('algolia-api-key')
        oktaAuth.signOut()
    }

    const loggedInAs = isAuthenticated ? authState.idToken.claims.email : ''

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleNavigateToTagsSection = () => {
        window.location.href = `${window.location.origin}/tags`
    }

    const handleNavigateToHomeSection = () => {
        window.location.href = `${window.location.origin}/search`
    }

    if (userRole === 'OPERATIONS') pages = ['Search']
    if (userRole === 'INVESTOR') pages = ['Tags', 'Search']
    if (userRole === 'OPERATIONS') pages = ['Tags', 'Search', 'Admin']

    return (
        <AppBar
            position="fixed"
            sx={{
                pl: 0,
                pr: 2,
                boxShadow: 'unset',
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
            }}>
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                <Box
                    sx={{
                        width: 'fit-content',
                        display: { xs: 'flex', md: 'none', fontSize: '40px' },
                        pl: 2,
                        pr: 2
                    }}>
                    <IconButton
                        size="medium"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' }
                        }}>
                        {pages.map((page) => {
                            return (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        onClick={() => {
                                            window.location.href = `${window.location.origin}/${page}`
                                        }}>
                                        {page}
                                    </Typography>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Box>
                <Box
                    sx={{ display: { xs: 'none', md: 'flex' }, cursor: 'pointer' }}
                    onClick={() => window.open(window.location.origin, '_self')}>
                    <Image
                        alt="Greybase"
                        src="/logo.png"
                        width="64px"
                        height="64px"
                        duration={0}
                        style={{
                            padding: '3px',
                            borderRadius: 5
                        }}
                    />

                    {!isMobile && (
                        <Typography
                            component="div"
                            onClick={() => window.open(window.location.origin, '_self')}
                            sx={{
                                display: 'grid',
                                cursor: 'pointer',
                                alignSelf: 'center',
                                textAlign: 'center',
                                fontSize: '34px',
                                fontWeight: 'middle',
                                fontFamily: 'KazimirText-Regular, KazimirText-Regular',
                                transitionProperty: 'font-size',
                                transitionDuration: '0.5s',
                                transitionDelay: '0',
                                ml: 2
                            }}>
                            Greybase
                        </Typography>
                    )}
                </Box>
                <Typography
                    variant="h4"
                    color={'white'}
                    noWrap
                    component="a"
                    href=""
                    maxWidth={120}
                    sx={{
                        display: {
                            md: 'none'
                        },
                        mr: 2,
                        flexGrow: 1,
                        fontSize: '24px',
                        fontWeight: 'bolder',
                        letterSpacing: '.1rem',
                        textDecoration: 'none !important',
                        fontFamily: 'Kazimir',
                        textAlign: 'center',
                        color: 'white !important'
                    }}>
                    Greybase
                </Typography>
                <Box
                    sx={{
                        pl: '40px',
                        pt: 0,
                        display: { xs: 'none', md: 'flex' }
                    }}>
                    {false && userRole === 'ADMIN' && (
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: 18,
                                mr: 2,
                                color: '#64A464',
                                backgroundColor: '#44516268',
                                ':hover': {
                                    backgroundColor: '#161C24'
                                },
                                boxShadow: 'none !important'
                            }}
                            onClick={() => {
                                window.location.href = `${window.location.origin}/admin`
                            }}>
                            <SettingsIcon
                                sx={{
                                    ml: 0,
                                    mr: 2,
                                    fontSize: '30px',
                                    cursor: 'pointer',
                                    ':hover': {
                                        border: '1px solid #green'
                                    }
                                }}
                            />
                            Admin
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        sx={{
                            fontSize: 18,
                            mr: 2,
                            color: !window.location.href.toLowerCase().includes('tags')
                                ? '#64A464'
                                : 'white',
                            backgroundColor: '#44516268',
                            ':hover': {
                                backgroundColor: '#161C24'
                            },
                            boxShadow: 'none !important'
                        }}
                        onClick={() => {
                            window.location.href = `${window.location.origin}`
                        }}>
                        <SearchIcon
                            sx={{
                                ml: 0,
                                mr: 2,
                                fontSize: '30px',
                                cursor: 'pointer',
                                ':hover': {
                                    border: '1px solid #green'
                                }
                            }}
                        />
                        Search
                    </Button>
                    {userRole !== 'OPERATIONS' && (
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: 18,
                                mr: 2,
                                color: window.location.href.toLowerCase().includes('tags')
                                    ? '#64A464'
                                    : 'white',
                                backgroundColor: '#44516268',
                                ':hover': {
                                    backgroundColor: '#161C24'
                                },
                                boxShadow: 'none !important'
                            }}
                            onClick={handleNavigateToTagsSection}>
                            <ClassIcon
                                sx={{
                                    ml: 0,
                                    mr: 2,
                                    fontSize: '30px',
                                    cursor: 'pointer',
                                    ':hover': {
                                        border: '1px solid #green'
                                    }
                                }}
                            />
                            Tags
                        </Button>
                    )}
                </Box>
                <Box
                    sx={{
                        pl: '40px',
                        pt: 0,
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' }
                    }}></Box>

                <Box sx={{ flexGrow: 0 }}>
                    {isDevelopment && (
                        <Button
                            onClick={handleCloseNavMenu}
                            variant="contained"
                            color="success"
                            sx={{
                                mr: 2,
                                backgroundColor: PRIMARY_MAIN,
                                color: 'white',
                                fontSize: 14,
                                fontWeight: 600,
                                fontFamily: 'inherit',
                                flexGrow: 1,
                                display: { xs: 'none', md: 'inline' }
                            }}>
                            DEVELOPMENT
                        </Button>
                    )}
                    <Button
                        onClick={handleCloseNavMenu}
                        variant="outlined"
                        color="success"
                        sx={{
                            mr: 2,
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: 'inherit',
                            flexGrow: 1,
                            display: { xs: 'none', md: 'none', xl: 'inline' }
                        }}>
                        {loggedInAs}
                    </Button>
                    {!isMobile && (
                        <Button variant="text" sx={{ mr: 1 }}>
                            {userRole}
                        </Button>
                    )}
                    <Button
                        onClick={handleCloseNavMenu}
                        variant="text"
                        color="success"
                        sx={{
                            mr: 2,
                            color: 'white',
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: 'inherit',
                            flexGrow: 1,
                            display: { xs: 'none', md: 'none', xl: 'inline' }
                        }}>
                        <span style={{ color: 'green' }}>
                            {getUserTag(tagsByEmail, loggedInAs)}{' '}
                        </span>
                        {getName(tagsByEmail, loggedInAs)}
                    </Button>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="User" src={getAvatarSrc(tagsByEmail, loggedInAs)} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '50px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}>
                        <MenuItem key="logout-profile-key" onClick={logout}>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default ResponsiveAppBar
